import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { BREAKPOINT, SPACING } from '../constants'

const StyledSideNavContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT.L}) {
    /* margin-top: ${SPACING.XXXL}; */
    flex-direction: column;
    justify-content: flex-start;
    position: sticky;
    top: 110px;
  }
`

const SideNavContainer = ({ children }) => (
  <StyledSideNavContainer>{children}</StyledSideNavContainer>
)
SideNavContainer.propTypes = {
  children: propTypes.oneOfType([
    propTypes.objectOf(propTypes.any).isRequired,
    propTypes.arrayOf(propTypes.any).isRequired,
  ]).isRequired,
}

export default SideNavContainer
