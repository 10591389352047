import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { BREAKPOINT, SPACING, WIDTH } from '../constants'

const StyledSideNav = styled.nav`
  margin-right: ${SPACING.XS};
  width: ${WIDTH.LINE_LIMIT};
  max-width: 37rem;

  margin: 0 auto;
  margin-top: ${SPACING.L};

  a img {
    max-width: 18rem;
  }

  @media (min-width: ${BREAKPOINT.L}) {
    margin: ${SPACING.XXXL} ${SPACING.XXL} 0 0;
    max-width: 18rem;

    a img {
      max-width: 100%;
    }
  }
`

const SideNav = ({ children }) => <StyledSideNav>{children}</StyledSideNav>
SideNav.propTypes = {
  children: propTypes.objectOf(propTypes.any).isRequired,
}

export default SideNav
