import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

const StyledSideNavList = styled.ul`
  margin-left: 0;
  margin-top: 20px;
`

const SideNavList = ({ children }) => (
  <StyledSideNavList>{children}</StyledSideNavList>
)
SideNavList.propTypes = {
  children: propTypes.arrayOf(propTypes.any).isRequired,
}

export default SideNavList
