import React from 'react'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { FONT, COLOR } from '../constants'

const StyledLink = styled(Link)`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.M};
  line-height: 1.5;
  font-weight: ${FONT.WEIGHT.BOLD};
  /* text-decoration: none; */
  color: ${COLOR.TEAL.MEDIUM};
  /* border-bottom: 1px solid ${COLOR.GRAY.DARK}; */
  &:visited {
    color: ${COLOR.TEAL.MEDIUM};
  }
  &:hover {
    color: ${COLOR.GRAY.DARK};
    /* border: 1px solid ${COLOR.GRAY.DARK}; */
  }
`

const InternalLink = ({ children, to }) => (
  <StyledLink to={to}>{children}</StyledLink>
)

InternalLink.propTypes = {
  children: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
}

export default InternalLink
