import styled from 'styled-components'
import { BREAKPOINT, SPACING, WIDTH } from '../constants'

const DetailContent = styled.div`
  max-width: ${WIDTH.LINE_LIMIT};
  margin-right: 0px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.L}) {
    margin-right: ${SPACING.XXXL};
    margin-left: 0;
  }
`
export default DetailContent
