import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

const Li = styled.li`
  list-style: none;
`

const SideNavListItem = ({ children }) => <Li>{children}</Li>
SideNavListItem.propTypes = {
  children: propTypes.objectOf(propTypes.any).isRequired,
}

export default SideNavListItem
