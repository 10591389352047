import React from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import { H2, SideNavHeading } from '../components/Typography'
import RichText from '../components/RichText'
import ContentWrapper from '../components/ContentWrapper'
import DetailContainer from '../components/DetailContainer'
import DetailContent from '../components/DetailContent'
import SideNav from '../components/SideNav'
import SideNavContainer from '../components/SideNavContainer'
import SideNavList from '../components/SideNavList'
import SideNavListItem from '../components/SideNavListItem'
import Anchor from '../components/Anchor'
import InternalLink from '../components/InternalLink'

const AboutPage = ({ data: { about } }) => (
  <Layout title="About Design Patterns for Mental Health">
    <Hero type="about" heading={about.heroHeading} />
    <ContentWrapper>
      <DetailContainer>
        <DetailContent>
          {about.about && (
            <section>
              <H2 id="about">About</H2>
              {RichText(about.about)}
            </section>
          )}

          {about.whatsInTheLibrary && (
            <section>
              <H2>
                <Anchor id="whats-in-the-library" />
                What&apos;s in the library
              </H2>
              {RichText(about.whatsInTheLibrary)}
            </section>
          )}

          {about.howWeBuiltIt && (
            <section>
              <H2>
                <Anchor id="how-we-built-it" />
                How we built it
              </H2>
              {RichText(about.howWeBuiltIt)}
            </section>
          )}

          {about.howToGetInvolved && (
            <section>
              <H2>
                <Anchor id="how-to-get-involved" />
                How to get involved
              </H2>
              {RichText(about.howToGetInvolved)}
            </section>
          )}

          {about.whosContributed && (
            <section>
              <H2 id="whos-contributed">Who&apos;s contributed</H2>
              {RichText(about.whosContributed)}
            </section>
          )}
        </DetailContent>

        <SideNav>
          <SideNavContainer>
            <SideNavList>
              <SideNavHeading>On this page</SideNavHeading>
              {about.about && (
                <SideNavListItem>
                  <InternalLink to="#about">About</InternalLink>
                </SideNavListItem>
              )}

              {about.whatsInTheLibrary && (
                <SideNavListItem>
                  <InternalLink to="#whats-in-the-library">
                    What&apos;s in the library
                  </InternalLink>
                </SideNavListItem>
              )}

              {about.whatsInTheLibrary && (
                <SideNavListItem>
                  <InternalLink to="#how-we-built-it">
                    How we built it
                  </InternalLink>
                </SideNavListItem>
              )}

              {about.whatsInTheLibrary && (
                <SideNavListItem>
                  <InternalLink to="#how-to-get-involved">
                    How to get involved
                  </InternalLink>
                </SideNavListItem>
              )}

              {about.whatsInTheLibrary && (
                <SideNavListItem>
                  <InternalLink to="#whos-contributed">
                    Who&apos;s contributed
                  </InternalLink>
                </SideNavListItem>
              )}
            </SideNavList>
          </SideNavContainer>
        </SideNav>
      </DetailContainer>
    </ContentWrapper>
  </Layout>
)

export const query = graphql`
  {
    about: contentfulAboutPage(setAsAboutPage: { eq: "yes" }) {
      heroHeading {
        json
      }
      about {
        json
      }
      whatsInTheLibrary {
        json
      }
      howWeBuiltIt {
        json
      }
      howToGetInvolved {
        json
      }
      whosContributed {
        json
      }
    }
  }
`

AboutPage.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export default AboutPage
