import styled from 'styled-components'
import { BREAKPOINT } from '../constants'

const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: ${BREAKPOINT.L}) {
    flex-direction: row;
  }
`
export default DetailContainer
